import {msg, str} from '@lit/localize';
import {css, html, LitElement, nothing} from 'lit';
import {classMap} from 'lit/directives/class-map.js';
import {property, state} from 'lit/decorators.js';

export class GameTile extends LitElement {
    static override styles = css`__CSS_CODE__`;

    @property({type: Boolean, attribute: 'is-logged-in'})
    isLoggedIn = false;

    @property({type: Boolean, attribute: 'is-live-casino-game'})
    isLiveCasino = false;

    @property({type: String, attribute: 'url-alias'})
    urlAlias = '';

    @property({type: String, attribute: 'display-name'})
    displayName = '';

    @property({type: String, attribute: 'icon-url'})
    iconUrl = '';

    @property({type: String, attribute: 'jackpot-amount'})
    jackpotAmount = '';

    @property({type: String, attribute: 'jackpot-updated-last'})
    jackpotUpdatedLast = '';

    @property({type: String, attribute: 'jackpot-increment'})
    jackpotIncrement = '';

    @property({type: String, attribute: 'jackpot-increment-tick'})
    jackpotIncrementTick = '';

    @property({type: String, attribute: 'game-uuid'})
    gameUuid = '';

    @property({type: Array, attribute: 'badges'})
    badges?: string[];

    @property({type: String, attribute: 'launch-origin'})
    launchOrigin = '';

    @property({type: String, attribute: 'position'})
    position = '';

    @property({type: String, attribute: 'currency'})
    currency = 'USD';

    @property({type: String, attribute: 'language'})
    language = 'en';

    @property({type: String, attribute: 'country'})
    country = 'US';

    @property({type: Boolean, attribute: 'hide-menu-button'})
    hideMenuButton = false;

    @property({type: Boolean, attribute: 'is-favourited'})
    isFavourited = false;

    @property({type: String, attribute: 'layout'})
    layout = 'default';

    @property({type: String, attribute: 'loading'})
    loading = 'eager';

    @property({type: String, attribute: 'site-id'})
    siteId = '';

    @property({type: String, attribute: 'player-flag'})
    playerFlag = '0';

    @property({type: Boolean, attribute: 'only-one-open'})
    onlyOneOpen = false;

    @state()
    private _favouriteDisabled = false;

    @state()
    private _isFavourited = false;

    @state()
    private _gametileMenuActive = false;

    @state()
    private _errorOccured = false;

    @state()
    private _gametileMenuContentLoading = false;

    @state()
    private _betRange: String = '';

    @state()
    private _dealerName: String = '';

    @state()
    private _blackjackOccupiedSeats: boolean[] = [];
/*
    @state()
    private _baccaratHistory: string[] = [];

    @state()
    private _rouletteHistory: number[] = [];
*/
    @state()
    private _realRTP: String = '';

    @state()
    private _theoreticalRTP: String = '';

    private _displayMaintenance = false;
    private _displayExclusive = false;
    private _displayNew = false;
    private _displayHot = false;
    private _displayTournament = false;

    private gametileMenuRequestController: null | AbortController = null;

    private TOAST_TITLE_SUCCESS = '';
    private TOAST_TITLE_FAILURE = '';
    private TOAST_TEXT_ADD_SUCCESS = '';
    private TOAST_TEXT_ADD_FAILURE = '';
    private TOAST_TEXT_REMOVE_SUCCESS = '';
    private TOAST_TEXT_REMOVE_FAILURE = '';

    private gameUrl = '';
    private gameUrlGametileMenu = '';
        
    override connectedCallback(): void {
        super.connectedCallback();
        const urlSearchParams = new URLSearchParams({
            launchOrigin: this.launchOrigin,
            launchOriginUrl: window.location.pathname,
            position: this.position,
        });
        const urlSearchParamsGametileMenu = new URLSearchParams({
            launchOrigin: `${this.launchOrigin}-gametile-menu`,
            launchOriginUrl: window.location.pathname,
            position: this.position,
        });
        this.gameUrl = `${window.location.origin}/play/${
            this.urlAlias
        }?${urlSearchParams.toString()}`;
        this.gameUrlGametileMenu = `${window.location.origin}/play/${
            this.urlAlias
        }?${urlSearchParamsGametileMenu.toString()}`;
        this._isFavourited = !!(this.isLoggedIn && this.isFavourited);

        this.bindEvents();
        this.calculateBadges();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
    }

    removeEvents() {
        removeEventListener('message', this.messageCallback.bind(this));
    }

    bindEvents() {
        // global post message listener
        addEventListener('message', this.messageCallback.bind(this));
    }

    calculateBadges() {
        let count = 0;
        this._displayMaintenance = !!this.badges?.includes('MAINTENANCE');
        if (this._displayMaintenance) {
            count++;
        }
        this._displayExclusive = !!this.badges?.includes('EXCLUSIVE');
        if (this._displayExclusive) {
            count++;
        }
        this._displayNew = !!this.badges?.includes('NEW') && count < 2;
        if (this._displayNew) {
            count++;
        }
        this._displayHot = !!this.badges?.includes('HOT') && count < 2;
        if (this._displayHot) {
            count++;
        }
        this._displayTournament =
            !!this.badges?.includes('TOURNAMENT') && count < 2;

        this.TOAST_TITLE_SUCCESS = msg(str`Success!`);
        this.TOAST_TITLE_FAILURE = msg(str`Failure!`);
        this.TOAST_TEXT_ADD_SUCCESS = msg(
            str`Added ${this.displayName} to your favourites.`
        );
        this.TOAST_TEXT_ADD_FAILURE = msg(
            str`Could not add ${this.displayName} to your favourites.`
        );
        this.TOAST_TEXT_REMOVE_SUCCESS = msg(
            str`Removed ${this.displayName} from your favourites.`
        );
        this.TOAST_TEXT_REMOVE_FAILURE = msg(
            str`Could not remove ${this.displayName} from your favourites.`
        );
    }

    pushToDataLayer(
        payload: Record<
            string,
            string | number | boolean | undefined | (() => void)
        >
    ): void {
        if (typeof window.dataLayer?.push !== 'function' || !payload) {
            return;
        }
        payload.uiprovider = document.cookie.match(
            '(^|;)\\s*Embedded_Referrer\\s*=\\s*([^;]+)'
        )?.pop() || 'ENJOY';
        window.dataLayer.push(payload);
    }

    async toggleGametileMenu() {
        if (this._gametileMenuActive) {
            this._gametileMenuContentLoading = false;

            this.closeGametileMenu();
            return;
        }
        this._gametileMenuContentLoading = true;
        this._gametileMenuActive = true;
        this._errorOccured = false;
        if (this.onlyOneOpen) {
            window.postMessage(
                {
                    action: 'closeGameTileGametileMenu',
                    uuidTrigger: this.gameUuid,
                },
                '*'
            );
        }
        /* Event that can be listened to track the gametileMenu opens */
        const event = new CustomEvent('gametile-menu-opened', {
            detail: {
                message: 'GametileMenu opened' },
        });
        this.dispatchEvent(event);
        this.pushToDataLayer({
            event: 'gametile opened',
            gameName: this.displayName,
            gamePosition: this.position,
            origin: this.launchOrigin,
            originUrl: window.location.pathname,
        });

        this.gametileMenuRequestController = new AbortController();
        try {
            /* Generate search query params for currency, language, country and gameUuid */
            const searchParams = new URLSearchParams({
                currency: this.currency,
                language: this.language,
                country: this.country,
                site_id: this.siteId,
                site_code: window.gameJourney.availability.siteCode,
                player_flag: this.playerFlag,
            });

            const result = await fetch(
                `/fragment/gamefilter/ajax/game-meta-data/${
                    this.gameUuid
                }?${searchParams.toString()}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${top?.JWToken}`,
                    },
                    signal: this.gametileMenuRequestController.signal,
                }
            );

            if (!result.ok) {
                throw new Error('Could not fetch gametileMenu data');
            }            
            const data = await result.json();
            this._betRange = data.betRange;
            this._realRTP = data.realRtp;
            this._theoreticalRTP = data.theoreticalRtp;
        } catch (error) {
            this._errorOccured = true;
        } finally {
            this._gametileMenuContentLoading = false;
        }
    }

    closeGametileMenu() {
        this._gametileMenuActive = false;
        this.gametileMenuRequestController?.abort();
        this.pushToDataLayer({
            event: 'gametile closed',
            gameName: this.displayName,
            gamePosition: this.position,
            origin: this.launchOrigin,
            originUrl: window.location.pathname,
        });
        /* Event that can be listened to track the gametileMenu opens */
        const event = new CustomEvent('gametile-menu-closed', {
            detail: {
                message: 'GametileMenu closed',
                bubbles: true,
                composed: true,
            },
        });
        this.dispatchEvent(event);
    }
      

    async toggleFavourite() {
        if (this._favouriteDisabled) {
            return;
        }

        let TOAST_TITLE = '';
        let TOAST_TEXT = '';
        // Disable the input element until the request has been completed
        this._favouriteDisabled = true;
        const newState = !this._isFavourited;
        this.pushToDataLayer({
            event: 'favourite',
            favourite: newState,
            gameName: this.displayName,
            gamePosition: this.position,
            origin: this.launchOrigin,
            originUrl: window.location.pathname,
        });

        try {
            // Issue a request to mark/unmark the given game as a favourite
            // Uses the state of the input element to determine which HTTP method to use
            const response = await fetch(
                `/fragment/gamefilter/ajax/favourites/${this.gameUuid}`,
                {
                    method: newState ? 'POST' : 'DELETE',
                    headers: {Authorization: `Bearer ${top?.JWToken}`},
                }
            );

            if (response.ok) {
                window.postMessage(
                    {
                        action: 'favourites',
                        gameTileFavouriteStatus: newState,
                        uuid: this.gameUuid,
                    },
                    '*'
                );

                TOAST_TITLE = this.TOAST_TITLE_SUCCESS;
                TOAST_TEXT = newState
                    ? this.TOAST_TEXT_ADD_SUCCESS
                    : this.TOAST_TEXT_REMOVE_SUCCESS;
            } else {
                TOAST_TITLE = this.TOAST_TITLE_FAILURE;
                TOAST_TEXT = newState
                    ? this.TOAST_TEXT_ADD_FAILURE
                    : this.TOAST_TEXT_REMOVE_FAILURE;
            }
        } catch {
            TOAST_TITLE = this.TOAST_TITLE_FAILURE;
            TOAST_TEXT = newState
                ? this.TOAST_TEXT_ADD_FAILURE
                : this.TOAST_TEXT_REMOVE_FAILURE;
        } finally {
            // Enable the input element when the request has been completed
            this._favouriteDisabled = false;

            this.issueToastMessage(TOAST_TITLE, TOAST_TEXT);
        }
    }

    /**
     * Issues a toast message with the given title and text
     */
    issueToastMessage(toastTitle: string, toastText: string): void {
        top?.postMessage(
            {channel: 'INSTANT_TOAST', title: toastTitle, text: toastText},
            top?.origin
        );
    }

    messageCallback(event: MessageEvent) {
        if (!top?.location.origin.includes(event.origin)) {
            return;
        }

        const data = event.data;
        switch (data.action) {
            case 'favourites':
                this.onFavouriteMessage(data.uuid, data.gameTileFavouriteStatus);
                break;
            case 'closeGameTileGametileMenu':
                this.onCloseGameTileMenuMessage(data.uuidTrigger);
                break;
            case 'live-casino-table-data':
                data.liveGameData.forEach((game: LiveDataTile) =>
                    {
                        this.onLiveTileMessage(game)
                    }
                )
                break;
            default:
                break;
        }
    }

    onFavouriteMessage(gameId: string, favouriteStatus: boolean) {
        if (gameId !== this.gameUuid || null === favouriteStatus) return;

        this._isFavourited = favouriteStatus;
    }

    onCloseGameTileMenuMessage(gameId: string) {
        if (this.onlyOneOpen) {
            /* Close this gametileMenu if another gametile opens their gametileMenu */
            if (gameId !== this.gameUuid) {
                this.closeGametileMenu();
            }
        }
    }

    onLiveTileMessage(tableData: LiveDataTile) {
        if (tableData.uuid !== this.gameUuid) return;

        this._dealerName = tableData.dealerName ?? '';
        this._blackjackOccupiedSeats = tableData.blackJackSeats ?? [];
        /*this._rouletteHistory = tableData.rouletteHistory ?? [];
        this._baccaratHistory = tableData.baccaratHistory ?? [];*/

    }

    displayFavouriteButton() {
        return this.isLoggedIn && top?.JWToken;
    }

    override render() {
        return html`
            <div class="game-item">
                <div class="main">
                    <div class="image-container">
                        <a
                            href="${this.gameUrl}"
                            title="${this.displayName}"
                            target="_top"
                            class="overflow-hidden"
                            rel="nofollow"
                            game-icon-anchor
                        >
                            <div class="image">
                                ${this.iconUrl
                                    ? html`<img
                                          src="${this.iconUrl}"
                                          alt="${this.displayName}"
                                          loading="${this.loading
                                              ? 'lazy'
                                              : 'eager'}"
                                      />`
                                    : nothing}
                            </div>
                        </a>
                        <!-- Render Jackpot amount -->
                        ${this.jackpotAmount
                            ? html`
                                  <div class="jackpot-container">
                                      <p class="jackpot-text">
                                          ${this.jackpotAmount}
                                      </p>
                                  </div>
                              `
                            : nothing}
                        <!-- Render badges -->
                        ${this.renderBadges()}

                        <!-- Render the favourite button -->
                        ${this.displayFavouriteButton()
                            ? html` <div class="favourite-container">
                                  <span
                                      @click="${this.toggleFavourite}"
                                      class="favourite-button ${classMap({
                                          'favourite-button-active':
                                              this._isFavourited,
                                      })}"
                                  >
                                      ${this._isFavourited
                                          ? this.favouriteButtonImage()
                                          : this.unfavouriteButtonImage()}
                                  </span>
                              </div>`
                            : nothing}
                    </div>
                </div>
                <div
                    class="game-tile-menu ${classMap({
                        'gametile-menu-active': this._gametileMenuActive,
                    })}"
                    @click="${this.closeGametileMenu}"
                >
                    ${this.gametileMenuContent()}
                </div>
                <div class="footer">
                    <div class="game-info__container">
                        <div class="game-info__main">
                            <a
                                href="${this.gameUrl}"
                                title="${this.displayName}"
                                target="_top"
                                rel="nofollow"
                                game-title-anchor
                            >
                                <p class="title">${this.displayName}</p>
                            </a>
                        </div>
                        ${!this.hideMenuButton
                            ? html`<div class="game-info__gametile-menu-button">
                                  <button
                                      class="gametile-menu-button"
                                      @click="${this.toggleGametileMenu}"
                                  >
                                      ${this._gametileMenuActive
                                          ? html`
                                                <svg
                                                    width="20"
                                                    height="21"
                                                    viewBox="0 0 20 21"
                                                    fill="none"
                                                    class="gametile-menu-button__img"
                                                >
                                                    <g
                                                        clip-path="url(#clip0_2507_2862)"
                                                    >
                                                        <path
                                                            d="M7 8.00006L13 14.0001"
                                                            stroke="currentColor"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                        />
                                                        <path
                                                            d="M7 13.9999L13 7.99994"
                                                            stroke="currentColor"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                        />
                                                        <path
                                                            d="M19.25 10.6746C19.25 5.56593 15.1086 1.42456 10 1.42456C4.89137 1.42456 0.75 5.56593 0.75 10.6746C0.75 15.7832 4.89137 19.9246 10 19.9246C15.1086 19.9246 19.25 15.7832 19.25 10.6746Z"
                                                            stroke="currentColor"
                                                            stroke-width="1.5"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath
                                                            id="clip0_2507_2862"
                                                        >
                                                            <rect
                                                                width="20"
                                                                height="21"
                                                                fill="currentColor"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            `
                                          : this.gametileMenuImage()}
                                  </button>
                              </div>`
                            : nothing}
                    </div>
                </div>
            </div>
        `;
    }

    gametileMenuImage() {
        return html`<svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            class="gametile-menu-button__img"
        >
            <path
                d="M7 11.6746C7.55228 11.6746 8 11.2268 8 10.6746C8 10.1223 7.55228 9.67456 7 9.67456C6.44772 9.67456 6 10.1223 6 10.6746C6 11.2268 6.44772 11.6746 7 11.6746Z"
                fill="currentColor"
            />
            <path
                d="M10 11.6746C10.5523 11.6746 11 11.2268 11 10.6746C11 10.1223 10.5523 9.67456 10 9.67456C9.44772 9.67456 9 10.1223 9 10.6746C9 11.2268 9.44772 11.6746 10 11.6746Z"
                fill="currentColor"
            />
            <path
                d="M13 11.6746C13.5523 11.6746 14 11.2268 14 10.6746C14 10.1223 13.5523 9.67456 13 9.67456C12.4477 9.67456 12 10.1223 12 10.6746C12 11.2268 12.4477 11.6746 13 11.6746Z"
                fill="currentColor"
            />
            <rect
                x="0.75"
                y="1.42456"
                width="18.5"
                height="18.5"
                rx="9.25"
                stroke="currentColor"
                stroke-width="1.5"
            />
        </svg>`;
    }

    renderBadges() {
        return html`
            ${this.badges
                ? html`<div class="badge-container">
                      ${this._displayMaintenance
                          ? html`
                                <div class="game-badge__pill">
                                    <p class="game-badge__text">
                                        ${msg(str`Maintenance`)}
                                    </p>
                                </div>
                            `
                          : nothing}
                      ${this._displayExclusive
                          ? html`
                                <div class="game-badge__pill">
                                    <p class="game-badge__text">
                                        ${msg(str`Exclusive`)}
                                    </p>
                                </div>
                            `
                          : nothing}
                      ${this._displayNew
                          ? html`
                                <div class="game-badge__pill">
                                    <p class="game-badge__text">
                                        ${msg(str`New`)}
                                    </p>
                                </div>
                            `
                          : nothing}
                      ${this._displayHot
                          ? html`
                                <div class="game-badge__pill">
                                    <p class="game-badge__text">
                                        ${msg(str`Popular`)}
                                    </p>
                                </div>
                            `
                          : nothing}
                      ${this._displayTournament
                          ? html`
                                <div class="game-badge__pill">
                                    <p class="game-badge__text">
                                        ${msg(str`Tournament`)}
                                    </p>
                                </div>
                            `
                          : nothing}
                  </div>`
                : nothing}
        `;
    }

    favouriteButtonImage() {
        return html`
            <svg
                class="game-favourite__img--filled"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="currentColor"
            >
                <path
                    d="M5.83329 1.5C3.53246 1.5 1.66663 3.34667 1.66663 5.625C1.66663 7.46417 2.39579 11.8292 9.57329 16.2417C9.70186 16.3199 9.84946 16.3613 9.99996 16.3613C10.1505 16.3613 10.2981 16.3199 10.4266 16.2417C17.6041 11.8292 18.3333 7.46417 18.3333 5.625C18.3333 3.34667 16.4675 1.5 14.1666 1.5C11.8658 1.5 9.99996 4 9.99996 4C9.99996 4 8.13413 1.5 5.83329 1.5Z"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        `;
    }
    unfavouriteButtonImage() {
        return html` <svg
            class="game-favourite__img--empty"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
        >
            <path
                d="M5.83329 1.5C3.53246 1.5 1.66663 3.34667 1.66663 5.625C1.66663 7.46417 2.39579 11.8292 9.57329 16.2417C9.70186 16.3199 9.84946 16.3613 9.99996 16.3613C10.1505 16.3613 10.2981 16.3199 10.4266 16.2417C17.6041 11.8292 18.3333 7.46417 18.3333 5.625C18.3333 3.34667 16.4675 1.5 14.1666 1.5C11.8658 1.5 9.99996 4 9.99996 4C9.99996 4 8.13413 1.5 5.83329 1.5Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>`;
    }

    gametileMenuContent() {
        if (!this._gametileMenuActive) {
            return html``;
        }

        return html`
            <div class="gametile-menu-content">
                <div class="gametile-menu-close-button">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                    >
                        <path
                            d="M1 1L11 11"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M1 11L11 1"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>

                ${this._theoreticalRTP || this._gametileMenuContentLoading
                    ? html` <div class="gametile-menu-item">
                          <span class="gametile-menu-item-title">
                              ${msg(str`Theoretical RTP:`)}
                          </span>
                          <span
                              class="gametile-menu-item-value ${classMap({
                                  blurry: this._gametileMenuContentLoading,
                              })}"
                          >
                              ${this._theoreticalRTP
                                  ? this._theoreticalRTP
                                  : '99.99%'}
                          </span>
                      </div>`
                    : nothing}
                ${this._realRTP || this._gametileMenuContentLoading
                    ? html`
                          <div class="gametile-menu-item">
                              <span class="gametile-menu-item-title">
                                  ${msg(str`RTP (24h):`)}
                              </span>
                              <span
                                  class="gametile-menu-item-value ${classMap({
                                      blurry: this._gametileMenuContentLoading,
                                  })}"
                              >
                                  ${this._realRTP ? this._realRTP : '99.99%'}
                              </span>
                          </div>
                      `
                    : nothing}
                ${this._betRange || this._gametileMenuContentLoading
                    ? html` <div class="gametile-menu-item">
                          <span class="gametile-menu-item-title">
                              ${msg(str`Bet range:`)}
                          </span>
                          <span
                              class="gametile-menu-item-value ${classMap({
                                  blurry: this._gametileMenuContentLoading,
                              })}"
                              >${this._betRange
                                  ? this._betRange
                                  : 'S100.00 - S123.45'}</span
                          >
                      </div>`
                    : nothing}
                ${ (this.isLiveCasino && this._dealerName) || (this.isLiveCasino && this._gametileMenuContentLoading) 
                    ? html` <div class="gametile-menu-item">
                            <span class="gametile-menu-item-title">
                                ${msg(str`Dealer name:`)}
                            </span>
                            <span
                                class="gametile-menu-item-value ${classMap({
                                    blurry: this._gametileMenuContentLoading,
                                })}"
                                >${this._dealerName
                                    ? this._dealerName
                                    : 'Unknown name'}</span
                            >
                        </div>`
                    : nothing}
                    ${this._blackjackOccupiedSeats.map(i => html` <div class="gametile-menu-item">
                        <span class="gametile-menu-item-title">
                                ${msg(str`Seat :`)}
                            </span>
                            <span
                                class="gametile-menu-item-value ${classMap({
                                    blurry: this._gametileMenuContentLoading,
                                })}"
                                >${i}</span
                            >
                        </div>`)}
                ${this.checkForMetaDataError() || this.checkForNoContent()
                    ? html`
                          <div
                              gametile-menu-error-message
                              class="gametile-menu-error"
                          >
                              <span class="gametile-menu-error-title">
                                  ${msg(str`No information available`)}
                              </span>
                          </div>
                      `
                    : nothing}
                <div class="gametile-menu-cta-section">
                    <a
                        class="play-button"
                        href="${this.gameUrlGametileMenu}"
                        title="${this.displayName}"
                        target="_top"
                        rel="nofollow"
                    >
                        ${msg(str`Play game`)}
                    </a>
                </div>
            </div>
        `;
    }
    checkForMetaDataError(){
        return !(this._theoreticalRTP || this._realRTP || this._betRange) &&
            !this._gametileMenuContentLoading &&
            this._errorOccured;
    }
    checkForNoContent() {
         return !(this._theoreticalRTP || this._realRTP || this._betRange ||
                 this._blackjackOccupiedSeats.length > 0 || this._dealerName) &&
             !this._gametileMenuContentLoading;
    }

}

declare global {
    interface postMessage {
        (event: object): void
    }

    interface HTMLElementTagNameMap {
        'game-tile': GameTile;
    }

    interface Window {
        dataLayer?: unknown[];
        JWToken?: string;
        liveCasinoWorker: {
            postMessage : postMessage
        },
        gameJourney: {
            availability: {
                currency: string;
                channel: string;
                language: string;
                country: string;
                siteCode: string;
                siteId: number
                playerFlag: number;
            }
        }
    }

    interface globalThis {
        HERE_WILL_BE_CSS: string;
    }

    interface LiveDataTile {
        uuid: string;
        dealerName: string;
        blackJackSeats: [];
        rouletteHistory: [];
        baccaratHistory: [];
    }
}

if (!customElements.get('game-tile')) {
    customElements.define('game-tile', GameTile);
}
